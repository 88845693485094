function httpGetAsync(theUrl, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      callback(xmlHttp.responseText);
    }
  };
  xmlHttp.open("GET", theUrl, true); // true for asynchronous
  xmlHttp.send(null);
  xmlHttp.onerror = function (error) {
    callback(null, error);
  };
}

function httpPostAsync(theUrl, data, callback) {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      callback(xmlHttp.responseText);
    } else if (xmlHttp.readyState == 4 && xmlHttp.status >= 400) {
      callback(null, xmlHttp.status);
    }
  };

  let serializedData = "";
  Object.entries(data).forEach((d) => {
    serializedData += `${d[0]}=${d[1]}&`;
  });
  xmlHttp.open("POST", theUrl, true); // true for asynchronous
  xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xmlHttp.send(serializedData);
}
